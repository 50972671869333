<template>
  <div class="auth-wrapper">
    <div class="auth-content">
      <div class="mb-4 text-center">
        <a href="/">
          <img src="../../assets/images/logo/logo_dark.png" class="img-fluid" />
        </a>
      </div>

      <div class="card">
        <div class="card-body">
          <notification-container />
          <BaseAlert v-if="error" :message="error" type="danger" />
          <form class="form" @submit.prevent="handleNewPasswordForm">
            <BaseInputPassword
              v-model="password"
              label="Nouveau mot de passe"
              placeholder="Mot de passe"
              :isInvalid="$v.password.$error || errors.password !== undefined"
              @blur="$v.password.$touch()"
            >
              <template v-if="$v.password.$error || errors.password">
                <label class="small form-text text-danger text-right">
                  <em v-if="!$v.password.required"
                    >le mot de passe est requis</em
                  >
                  <em v-if="!$v.password.minLength">au moins 6 caractères</em>
                  <em v-if="!$v.password.containsUppercase">
                    au moins une lettre en majuscule</em
                  >
                  <em v-if="!$v.password.containsNumber">
                    au moins un chiffre</em
                  >
                  <em v-if="errors.password">{{ errors.password[0] }}</em>
                </label>
              </template>
            </BaseInputPassword>
            <BaseInputPassword
              v-model="password_confirmation"
              label="Confirmer le mot de passe"
              placeholder="Confirmation"
              :isInvalid="$v.password_confirmation.$error"
              @blur="$v.password_confirmation.$touch()"
            >
              <template v-if="$v.password_confirmation.$error">
                <label class="small form-text text-danger text-right">
                  <em v-if="!$v.password_confirmation.required"
                    >la confirmation du mot de passe est requise</em
                  >
                  <em v-if="!$v.password_confirmation.sameAsPassword"
                    >La confirmation du mots de passe ne correspondent pas</em
                  >
                </label>
              </template>
            </BaseInputPassword>
            <hr />
            <BaseButton
              :disabled="disabledSubmitBtn"
              button-class="btn-block btn-secondary font-weight-bold"
              type="submit"
              :loading="loading"
              :showLoading="true"
            >
              Réinitialiser
            </BaseButton>
          </form>
        </div>
      </div>

      <div class="card mt-4">
        <div class="card-body text-center">
          Retourner à la page de
          <router-link :to="{ name: 'Login' }">Connexion</router-link>
        </div>
      </div>

      <Footer />
    </div>
  </div>
</template>

<script>
import Footer from "../../components/layouts/Footer";
import { required, minLength, sameAs } from "vuelidate/lib/validators";
import { recaptchaMixin } from "../../mixins/recaptchaMixin";
import NotificationContainer from "../../components/notification/NotificationContainer";
export default {
  name: "PasswordResetConfirm",
  mixins: [recaptchaMixin],
  components: { NotificationContainer, Footer },

  data() {
    return {
      loading: false,
      password: null,
      password_confirmation: null,
      error: null,
      errors: []
    };
  },

  computed: {
    disabledSubmitBtn() {
      return this.$v.$invalid || this.loading || this.recaptchaToken === null;
    }
  },

  methods: {
    handleNewPasswordForm() {
      if (!this.$v.invalid) {
        this.loading = true;
        this.$store
          .dispatch("auth/resetPassword", {
            token: this.$route.params.token,
            recaptcha: this.recaptchaToken,
            password: this.password
          })
          .then(() => {
            this.$router.push({ name: "Login", query: this.$route.query });
            this.loading = false;
          })
          .catch(err => {
            if (err.response.data.errors)
              this.errors = err.response.data.errors;
            if (err.response.data.message)
              this.error = err.response.data.message;
            this.loading = false;
            this.loadRecaptcha();
          });
      }
    }
  },

  validations: {
    password: {
      required,
      minLength: minLength(6),
      containsUppercase: function(value) {
        return /[A-Z]/.test(value) === true;
      },
      containsNumber: function(value) {
        return /[0-9]/.test(value) === true;
      }
    },
    password_confirmation: { required, sameAsPassword: sameAs("password") }
  }
};
</script>

<style scoped></style>
